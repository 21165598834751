export default {
  BREAK_POINT: 1024,
  DYNAMODB_NONE_CHARACTER: 'DYNAMODB-NONE-CHARACTER',
  HEROKU_APP_BASE_URL: 'https://cors-anywhere.herokuapp.com/', // TODO: Need to implement our own proxy (until that using this)
  SCREEN_LAYOUT_DEFAULT_SIZES: {
    RIGHT_PANE: 375,
    SIDE_BAR: 375,
  },
  COGNITO_ERROR_CODES: {
    USER_NOT_CONFIRMED: 'UserNotConfirmedException',
    PASSWORD_RESET_REQUIRED: 'PasswordResetRequiredException',
  },
  ERRORS: {
    MEDIA_TYPE_NOT_SUPPORTED_ERROR: 'MediaTypeNotSupportedError',
    MEDIA_NOT_AVAILABLE_ERROR: 'MediaNotAvailableError',
  },
  DURATION: {
    SECOND: 1000,
    MINUTE: 60 * 1000,
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
  },
  API_KEY: {
    ALL_BUSINESSES: 'allBusinesses',
    TRASH_BUSINESSES: 'trashBusinesses',
  },
  IMAGE_FETCH_TYPE: {
    PHOTO: 'photo',
    VIDEO_THUMBNAIL: 'videoThumbnail',
    PROFILE: 'profile',
    CHAT_ICON: 'chatIcon',
  },
  PAGE_SIZES: {
    BUSINESS_FETCH_BATCH_SIZE: 10,
    CLIPS_FETCH_BATCH_SIZE: 10,
  },
  USER_ACTIVE_ROLE: {
    userSignUpRole: 'user-signup-role',
    seekerRole: 'seeker-role',
    tradieSignUpRole: 'tradie-signup-role',
    tradieOwner: 'tradie-owner',
    tradieWorker: 'tradie-worker',
    tradieAdmin: 'tradie-admin',
    tradiePendingBusinessRole: 'tradie-pending-business-role',
    tradiePendingBusinessRejectedRole: 'tradie-pending-business-rejected-role',
  },
  NOTIFICATION_TYPE: {
    MESSAGE: 'MESSAGE',
    SYSTEM_MESSAGE: 'SYSTEM-MESSAGE',
    SYSTEM_MESSAGE_WEB: 'SYSTEM-MESSAGE-WEB',
    SYSTEM_EVENT: 'SYSTEM-EVENT',
    HTML_MESSAGE: 'HTML-MESSAGE',
    URL_MESSAGE: 'URL-MESSAGE',
  },
  TIMEOUT: {
    DEFAULT: 500,
    MODAL_HIDE: 1000,
    TYPE_WAITING: 1500, // reduced per Sajith's request
    TYPE_WAITING_DEFAULT: 2000, // reduced per Sajith's request
  },
  FILE_UPLOAD_STATUS: {
    NOT_UPLOADED: 'not-uploaded',
    UPLOADING: 'uploading',
    UPLOADED: 'uploaded',
    ERROR: 'error',
  },
  MAX_UPLOAD_ITEM_COUNT: 10,
  MEDIA_UPLOAD_TYPES: {
    IMAGES: 'images',
    VIDEOS: 'videos',
    AUDIOS: 'audios',
    MOD: 'mod', // modified file,
    PROFILE: 'profile',
    CHAT_ICONS: 'chat-icons',
    LICENSE: 'license',
  },
  MIME_TYPES: {
    PNG: 'image/png',
    HEIC: 'image/heic',
    HEIF: 'image/heif',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
  },
  MEDIA_ORIGIN_TYPES: {
    THREAD: 'thread',
    THREAD_SETTINGS: 'thread-settings',
    BUSINESS_PROFILE: 'business-profile',
    USER_PROFILE: 'user-profile',
  },
  TRADIE_TYPE: {
    BUSINESS_OWNER: 'business_owner',
    WORKER: 'worker',
    ADMIN: 'Foreman',
  },
  MEDIA_CATEGORY_TYPES: {
    MEDIA: 'media',
    DOCS: 'docs',
    CLIP: 'clip',
    THREAD_SETTINGS: 'thread-settings',
    BUSINESS_PROFILE: 'business-profile',
    USER_PROFILE: 'user-profile',
  },
  IMAGE_SIZES: {
    MESSAGE_BUBBLE: '240x210',
    AVATAR_LARGE: '150x150',
    AVATAR_SMALL: '20x20',
    STORY_THUMB: '170x235',
    STORY: 'story',
    GALLERY_THUMB: '125x125',
    REPLICA: 'replica',
  },
  KEYBOARD_TYPE: {
    NUMBER_PAD: 'number-pad',
    PHONE_PAD: 'phone-pad',
    EMAIL_ADDRESS: 'email-address',
    DECIMAL_PAD: 'decimal-pad',
  },
  AUS_STATES: ['NSW', 'VIC', 'QLD', 'WA', 'SA', 'NT', 'ACT', 'TAS'],
}
