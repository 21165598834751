import { Platform } from 'react-native'
import {
  ComponentOptions,
  showMessage,
  hideMessage,
} from '@gotradie/gt-components'
import Constants from './Constants'
import StorageUtil from './StorageUtil'
import {
  TaggedEntities,
  EntityType,
  TaggedEntityUser,
  TaggedEntityBusiness,
  Media,
  GalleyType,
} from '../types/Files'
import { v4 as uuidv4 } from 'uuid'
const mimeDb = require('mime-db')
const _ = require('lodash')

function showErrorMessage(message: string) {
  showMessage({
    message: undefined,
    description: message,
    type: ComponentOptions.FLASH_MESSAGE_TYPES.ERROR,
    statusBarHeight: 80,
  })
  setTimeout(hideMessage, 3000)
}

function showSuccessMessage(message: string, containerStyle?: any) {
  showMessage({
    message: undefined,
    description: message,
    type: ComponentOptions.FLASH_MESSAGE_TYPES.SUCCESS,
    statusBarHeight: 80,
    icon: 'Valid',
    containerStyle: {
      alignSelf: 'center',
    },
  })
  setTimeout(hideMessage, 3000)
}

function generateUUID() {
  return uuidv4()
}

function formatMobileNumber(txt: string) {
  const isValid = txt.match(/^(?:\+?(61|94))/gm)
  let PhoneNumber = txt
  if (isValid === null) {
    // no countrycode
    if (txt.startsWith('0')) {
      PhoneNumber = txt.replace(/^0+/, '+61')
    } else {
      PhoneNumber = `+61${txt}`
    }
  }
  return PhoneNumber
}

function getTitleCase(str: string): string {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

function getMemberRoleForDisplay(roleType: string) {
  let memberType = ''
  if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    memberType = 'Owner'
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    memberType = 'Foreman'
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    memberType = 'Worker'
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    memberType = 'Worker(Pending)'
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    memberType = 'Worker(Rejected)'
  }
  return memberType
}

function getAcronym(name: string): string {
  if (name) {
    const text = name.trim().replace(/\s/g, '').toUpperCase()
    if (text.length === 0) {
      return ''
    }
    const letterNumber = /^[0-9a-zA-Z]+$/
    for (let i = 0; i < text.length; i++) {
      const ch = text.charAt(i)
      if (ch.match(letterNumber)) {
        return ch
      }
    }
    return ''
  } else {
    return ''
  }
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function hasActiveOrg(): boolean {
  StorageUtil.getActiveRole()
    .then((role) => {
      return role && role != null && role['org-id'] && role['org-id'] != ''
    })
    .catch(() => {
      return false
    })

  return false
}

async function getActiveOrgId(): Promise<string> {
  try {
    const activeRole = await StorageUtil.getActiveRole()
    return activeRole?.['org-id'] || ''
  } catch (error) {
    return ''
  }
}

function replaceUndefinedWithDefault(value: any, defaultValue: any) {
  return value === undefined ||
    value === null ||
    value === '' ||
    value === 'NONE' ||
    value === 'DYNAMODB_NONE_CHARACTER' ||
    value === 'DYNAMODB-NONE-CHARACTER'
    ? defaultValue
    : value
}

function getClipAvatarProps(
  taggedEntities: TaggedEntities,
  type: EntityType,
  id: string
) {
  let name = ''
  let s3Key = ''
  let uniqueId = ''
  let otherProps: TaggedEntityBusiness | TaggedEntityUser | object = {}
  if (taggedEntities) {
    if (type === EntityType.business) {
      const entity = taggedEntities[id] as TaggedEntityBusiness
      name = entity?.businessName || ''
      s3Key = entity.businessLogo
      uniqueId = entity.businessId
      otherProps = { ...entity }
    } else if (type === EntityType.user) {
      const entity = taggedEntities[id] as TaggedEntityUser
      name = entity?.firstName
      s3Key = entity.profilePic
      uniqueId = entity.userId
      otherProps = { ...entity }
    }
  }

  return { name, s3Key, uniqueId, otherProps }
}
function getPaddings(columns: number, padding: number, index: number) {
  let paddingLeft = padding / 2
  let paddingRight = padding / 2
  let paddingTop = padding / 2
  const paddingBottom = padding / 2

  const row: number = Math.floor(index / columns)
  const isFirstItem: boolean =
    index % columns === 0 || index % columns === columns
  const isLastItem: boolean = index % columns === columns - 1
  if (row === 0) {
    //first row
    paddingTop = padding
    if (isFirstItem) {
      paddingLeft = padding
    } else if (isLastItem) {
      paddingRight = padding
    }
  } else {
    if (isFirstItem) {
      paddingLeft = padding
    } else if (isLastItem) {
      paddingRight = padding
    }
  }

  return { paddingLeft, paddingRight, paddingTop, paddingBottom }
}

function getThumbnailKeyFromVideoObjectKey(key: string) {
  if (key) {
    const sections = key.split('/')
    if (sections.length !== 2) {
      return null
    }
    return `${sections[0]}/thumbnail/${sections[1]}`
  }
  return null
}

function removeDuplicatesFromArray(ar: Array<any>, key = '') {
  const unique = ar.filter((value, index, self) => {
    if (key) {
      return self.indexOf(value[key]) === index
    }
    return self.indexOf(value) === index
  })

  return unique
}

function getGalleyTypeOfMedia(mediaType: string) {
  let galleryType: GalleyType = GalleyType.photo
  if (mediaType === GalleyType.audio) {
    galleryType = GalleyType.audio
  } else if (mediaType === GalleyType.video) {
    galleryType = GalleyType.video
  }
  return galleryType
}
function getMediaPlayerTime(duration: number, currentPosition: number) {
  if (duration === 0) {
    return '0:00'
  } else {
    const timeDiff = Math.floor(duration - currentPosition)
    let seconds: number = Math.floor(timeDiff)
    const minutes: number = Math.floor(seconds / 60)
    seconds = seconds % 60
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds
  }
}

function getFileExtension(filename: string) {
  return (
    filename.substring(filename.lastIndexOf('.') + 1, filename.length) ||
    filename
  )
}

function getDefaultExtension(type: GalleyType, item: Media) {
  if (type === GalleyType.photo) {
    return mimeDb[item.mimeType].extensions[0]
  } else if (type === GalleyType.video) {
    return Platform.OS === 'ios' ? 'm4a' : 'mp4'
  }
  return null
}

function chunkArrayToGivenSizes(arr: any[], arSizes: any[]) {
  const arChunks = []
  let lastSlicedIndex = 0

  for (let index = 0; index < arSizes.length; index++) {
    const size = arSizes[index]

    if (lastSlicedIndex < arr.length) {
      const chunkedAr = arr.slice(lastSlicedIndex, lastSlicedIndex + size)
      arChunks.push(chunkedAr)
      lastSlicedIndex = lastSlicedIndex + size
    } else {
      break
    }
  }
  return arChunks
}

function getUniqueListBy(arr: Array<any>, key: string) {
  return Array.from(new Map(arr.map((item) => [item[key], item])).values())
}

function getTradieTypeFromRole(roleType: string) {
  const LIST_TRADIE_TYPE = Constants.TRADIE_TYPE

  let ACTIVE_TRADIE_TYPE = ''

  if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.BUSINESS_OWNER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.ADMIN
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieOwner) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.BUSINESS_OWNER
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieAdmin) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.ADMIN
  } else if (roleType === Constants.USER_ACTIVE_ROLE.tradieWorker) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  } else if (
    roleType === Constants.USER_ACTIVE_ROLE.tradiePendingBusinessRejectedRole
  ) {
    ACTIVE_TRADIE_TYPE = LIST_TRADIE_TYPE.WORKER
  }

  return ACTIVE_TRADIE_TYPE
}

function removeUndefinedProps(obj: any) {
  return JSON.parse(JSON.stringify(obj))
}

function checkObjectIsExistsInArray(
  ar: any,
  obj: any,
  key = '',
  keyArray = ''
) {
  if (key !== '') {
    const idKey = keyArray === '' ? key : keyArray
    return ar.find((o: any) => o[idKey] === obj[key]) !== undefined
  }
  return ar.find((o: any) => o === obj) !== undefined
}

function checkObjectsAttributesExistsInArray(ar: any, attributes = {}) {
  return _.find(ar, attributes)
}

function validateEmail(email: string) {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return re.test(email)
}

function openNewTab(url: string) {
  window.open(url, '_blank')
}

function replaceEmptyWithUndefined(value: any) {
  if (typeof value === 'object' && _.isEmpty(value)) {
    return undefined
  } else if (typeof value === 'string' && value === '') {
    return undefined
  } else if (Array.isArray(value) && value.length === 0) {
    return undefined
  }
  return value
}

function generateQueryParameter(data: any, allowEmptyStrings = false) {
  let str = ''
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key]
      if (value !== null) {
        if (Array.isArray(value)) {
          if (value.length > 0) {
            if (str !== '') {
              str += '&'
            }
            str += `${key}=${value.join(',')}`
          }
        } else if (value !== undefined && value !== '') {
          if (str !== '') {
            str += '&'
          }
          str += `${key}=${value}`
        } else if (value !== undefined && allowEmptyStrings) {
          if (str !== '') {
            str += '&'
          }
          str += `${key}=${value}`
        }
      }
    }
  }
  if (str !== '') {
    str = `?${str}`
  }
  return str
}

function getStreetAddressFromDescription(description: string) {
  const arSplitAddress = description.split(',')
  return arSplitAddress.length > 0 ? arSplitAddress[0] : description
}

function convertArrayToString(
  arr: any,
  key: string | number,
  isTitleCase = false,
  seperator = ' | '
) {
  let data = ''
  let i = 0
  try {
    for (const v of arr) {
      if (i > 0) {
        data += seperator
      }

      const val = key === '' ? v : v[key]
      if (isTitleCase) {
        data += getTitleCase(val)
      } else {
        data += val
      }
      i++
    }
  } catch (error) {}
  return data
}

function copyToClipBoard(text: string, msg: string) {
  try {
    navigator.clipboard.writeText(text)
    showSuccessMessage(msg)
  } catch (error) {
    showErrorMessage(`Copy link option not supporting`)
  }
}

export default {
  copyToClipBoard,
  convertArrayToString,
  getStreetAddressFromDescription,
  generateQueryParameter,
  showSuccessMessage,
  showErrorMessage,
  hideMessage,
  generateUUID,
  formatMobileNumber,
  getTitleCase,
  getMemberRoleForDisplay,
  getAcronym,
  getWindowDimensions,
  hasActiveOrg,
  getActiveOrgId,
  replaceUndefinedWithDefault,
  getClipAvatarProps,
  getPaddings,
  getThumbnailKeyFromVideoObjectKey,
  removeDuplicatesFromArray,
  getGalleyTypeOfMedia,
  getMediaPlayerTime,
  getFileExtension,
  getDefaultExtension,
  chunkArrayToGivenSizes,
  getUniqueListBy,
  getTradieTypeFromRole,
  removeUndefinedProps,
  checkObjectIsExistsInArray,
  checkObjectsAttributesExistsInArray,
  validateEmail,
  openNewTab,
  replaceEmptyWithUndefined,
}
